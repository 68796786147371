import React from 'react';
import ReactDOM from 'react-dom';

const APP = (  
    <div>            
    </div>  
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(APP, rootElement);
} else {
  ReactDOM.render(APP, rootElement);
}